body {
  font-family: "Didact Gothic", sans-serif !important;
  font-weight: 400;
  font-style: normal;
  background-color: #f2f2f2;
}

p {
  font-size: 18px; 
}

li {
  font-size: 18px;
}
#banner-image {
  background: url('/public/images/banner.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: calc(100vw / 4.75); /* Maintain aspect ratio of 940x198 */
}
